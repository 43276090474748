<template>
  <v-flex xs12 class="padContainer">
    <v-card @click.native="clicked()" style="width: 100%;margin-bottom: 1px;" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
      <v-container fluid style="padding: 10px;">

        <v-card-text style="padding: 2px;">
          <v-layout wrap>
            <v-flex xs9>
              <div style="font-size: 1.3em;">{{lItem.name}}</div>
            </v-flex>
            <v-flex xs3 style="text-align:right;">
              <span v-if="lItem.date !== ''">{{ lItem.date }}</span>
              <span v-if="lItem.type !== '' && lItem.date === ''">{{ lItem.type }}</span>
            </v-flex>
            <v-flex xs12 style="text-align:right;">
              <span>{{lItem.currency}} {{lItem.amount}}</span>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-dialog v-model="lItem.show" scrollable :fullscreen="$vuetify.breakpoint.smAndDown" v-if="$vuetify.breakpoint.smAndDown" hide-overlay transition="dialog-bottom-transition">
          <ListItemData v-bind:lItem="lItem" v-on:EditItem="redirectToEditPage($event)" v-on:DeleteItem="DeleteItem($event)"></ListItemData>
        </v-dialog>

      </v-container>
    </v-card>
    </v-flex>
</template>

<script>
  import listItemData from './listItemData.vue'
    import { globalFunctions, formatMoney } from '../../javascript/globalFunctions'
  import { listItemFunctions } from '../../javascript/listItemManager'
  import { dateManager } from '../../javascript/dateManager'

  export default {
    components: {
      ListItemData: listItemData
    },
    props: ['lisItem'],
    data() {
      return {
        lItem: {
          id: 0,
          listType: '',
          name: '', 
          account: '',
          type: '',
          amount: 0,
          amountSpent: 0,
          refillAmount: 0,
          date: '',
          currency: '',

          show: false
        }
      }
    },
    methods: {
      redirectToEditPage: function (itemId) {
        this.$emit('Edit', itemId)
      },
      DeleteItem: function (lItem) {
        this.$emit('Delete', lItem)
      },
      itemClicked: function (item) {
        this.$emit('clickedItem', item)
      },
      clicked: function () {
        this.lItem.show = !this.lItem.show
        this.itemClicked(this.lItem)
      },
    },
    beforeMount() {
        this.lItem = globalFunctions.stringify(listItemFunctions.conversionManager(this.lisItem.type, this.lisItem.data))

        this.lItem.name = globalFunctions.stringify(globalFunctions.capitalizeFirstLetter(this.lItem.name))

        this.lItem.date = dateManager.reformatDateFromApi(this.lItem.date)

        this.lItem.amount = formatMoney(this.lItem.amount, 2, ".", ",")
    }
  }

</script>

<style scoped>
  .flex {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .padContainer {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }


</style>
