<template>
    <v-card style="height:100%">
      <v-toolbar text>
        <v-toolbar-title>{{lItem.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="mx-0" v-on:click.prevent="redirectToEditPage(lItem.id)" style="margin-right: 15px !important;">
          <v-icon color="teal">edit</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" v-on:click.prevent="DeleteItem(lItem.id)" style="margin-left: 15px !important;">
          <v-icon color="error">delete</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon text @click.native="closeView()"><v-icon>fas fa-backspace</v-icon></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!--<v-toolbar text style="width:170px;margin-left: auto;margin-right: auto;border-radius: 0 0 28px 28px;">
        <v-btn icon class="mx-0" v-on:click.prevent="redirectToEditPage(lItem.id)" style="margin-left: 15px !important;">
          <v-icon color="teal">edit</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon class="mx-0" v-on:click.prevent="DeleteItem(lItem.id)" style="margin-right: 15px !important;">
          <v-icon color="error">delete</v-icon>
        </v-btn>
      </v-toolbar>-->
      <v-card-text style="height: 100%;">
        <AccountData v-if="lItem.listType === 'account'" v-bind:accountId="lItem.id"></AccountData>
        <BudgetData v-if="lItem.listType === 'budget'" v-bind:budgetId="lItem.id"></BudgetData>
        <TransferData v-if="lItem.listType === 'transfer'" v-bind:transferId="lItem.id"></TransferData>
      </v-card-text>
    </v-card>
</template>

<script>
    import { globalFunctions } from '../../javascript/globalFunctions'

  export default {
    components: {
        AccountData: () => import('../../views/account/accountData.vue'),
        BudgetData: () => import('../../views/budget/budgetData.vue'),
        TransferData: () => import('../../views/transfer/transferData.vue'),
        IncomeData: () => import('../../views/income/incomeData.vue'),
        ExpenseData: () => import('../../views/expense/expenseData.vue')
        //TransactionData: () => import('../../../views/transaction/transactionData.vue')
    },
     props: ['lItem', "show",],
    //data() {
    //  return {
        
    //  }
    //},
    methods: {
        closeView: function () {
            this.lItem.show = false
            this.$emit('show', false)
        },
        redirectToEditPage: function (itemId) {
            this.$emit('EditItem', itemId)
        },
        DeleteItem: function (lItem) {
            this.$emit('DeleteItem', lItem)
        },
        
    },
    watch: {
        "lItem": function() {
            this.lItem.name = globalFunctions.capitalizeFirstLetter(this.lItem.name)
        }
    },
    beforeMount() {
        this.lItem.name = globalFunctions.capitalizeFirstLetter(this.lItem.name)
    }
   
  }

</script>
